import React from "react"
import { format } from "@/utils"
import { Table } from "antd"
import Heading from "@/components/Heading"
// import * as style from "./style.module.scss"

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (record) => <strong>{record}</strong>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (record, records) => (
      <strong>
        {format(record)} <span className="tada__ticker">{records.token}</span>
      </strong>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (record) => <div className="text-wrap min-width-500">{record}</div>,
  },
]

const data = [
  // {
  //   token: 'TADA',
  //   type: 'mint',
  //   date: '09/19/2021',
  //   amount: 10000000,
  //   description: '10,000,000 were sent to ISPO CeFi Distribution Wallet.'
  // },
  {
    token: "VNI",
    type: "mint",
    date: "09/19/2021",
    amount: 582550251,
    description:
      "582,550,251 VNI tokens were minted. 219,753,385 were sent to ISPO CeFi Distribution Wallet.",
  },
  {
    token: "TADA",
    type: "mint",
    date: "09/19/2021",
    amount: 582550251,
    description:
      "582,550,251 TADA tokens were minted. 219,753,385 were sent to ISPO CeFi Distribution Wallet.",
  },
]

const TadaTokenomics = () => {
  return (
    <div className="tada__block">
      <Heading id="events">
        <strong>Governance</strong> minting & burning events
      </Heading>
      <div className="tada__table">
        <Table dataSource={data} columns={columns} pagination={false} />
      </div>
    </div>
  )
}

export default TadaTokenomics
