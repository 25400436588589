import React from "react"
// import { format } from "@/utils"
import Heading from "@/components/Heading"
// import * as style from "./style.module.scss"

const TadaTokenomics = () => {
  return (
    <div className="tada__block">
      <Heading id="wallets">
        <strong>Governance</strong> wallets
      </Heading>
      <div className="tada__left tada__left--dark mb-4">
        <h5 className="mb-3">
          <strong>ISPO CeFi Distribution Wallet</strong>
        </h5>
        <p className="mb-3">
          Used to withdraw rewards from the Delegators Distribution Program
          (CeFi). Soon to be replaced by a smart contract
        </p>
        <a
          className="text-break"
          href="https://cardanoscan.io/address/01086ee905d3dd2844372c1fc1662f0e7b8eb2998485d6bb09e4f0e84e16dc66e2c35d21b4356e0348d7ccf4028093ae16df519b6bb2341cc4"
        >
          addr1qyyxa6g960wjs3ph9s0uze30peacav5esjzadwcfuncwsnskm3nw9s6ayx6r2msrfrtueaqzszf6u9kl2xdkhv35rnzqpfrnys
        </a>
      </div>
      {/* <div className="tada__left tada__left--dark mb-4">
        <h5 className="mb-3"><strong>Development & Marketing Fund Wallet</strong></h5>
        <p className="mb-3">
          Used to store development & marketing funds
        </p>
        <a className="text-break" href="https://cardanoscan.io/address/addr1qx6ndpw2uma2qytf2zynvv4crqdwkmck0a2r4vm20gwkzercd5kvnadmwssrwpce6x4c2rm7t6aj3rlkfh2f775fu7fqsdyray">
          addr1qx6ndpw2uma2qytf2zynvv4crqdwkmck0a2r4vm20gwkzercd5kvnadmwssrwpce6x4c2rm7t6aj3rlkfh2f775fu7fqsdyray
        </a>
      </div>
      <div className="tada__left tada__left--dark mb-4">
        <h5 className="mb-3"><strong>XDIAMOND Sale Wallet</strong></h5>
        <p className="mb-3">
          Storage wallet for distribution <span className="tada__ticker">XDIAMOND</span> sale
        </p>
        <a className="text-break" href="https://cardanoscan.io/address/addr1q9sl4qydrgmtf922ypdffu5x2zxeg7jpk9ryzazq0d03d5hqeaa9yhkregmcvp89pujgdcfcuextw0wkh2lczvhqz8jqk6rg3k">
          addr1q9sl4qydrgmtf922ypdffu5x2zxeg7jpk9ryzazq0d03d5hqeaa9yhkregmcvp89pujgdcfcuextw0wkh2lczvhqz8jqk6rg3k
        </a>
      </div>
      <div className="tada__left tada__left--dark mb-4">
        <h5 className="mb-3"><strong>XDIAMOND Collateral Wallet</strong></h5>
        <p className="mb-3">
          Collateral wallet with <span className="tada__ticker">XRAY</span> for redeeming <span className="tada__ticker">XDIAMOND</span> with rate 1 <span className="tada__ticker">XDIAMOND</span> = 100 <span className="tada__ticker">XRAY</span>
        </p>
        <a className="text-break" href="https://cardanoscan.io/address/addr1q9j55y5p7lyq3esn7xwrae5k9ez30639dj3ct7pp6fjkavvhuchtelm0kpr4pfvht57xcx4qd80tr3q9gg4s9h3d22uqjxm9p9">
          addr1q9j55y5p7lyq3esn7xwrae5k9ez30639dj3ct7pp6fjkavvhuchtelm0kpr4pfvht57xcx4qd80tr3q9gg4s9h3d22uqjxm9p9
        </a>
      </div> */}
    </div>
  )
}

export default TadaTokenomics
