import React from "react"
import { Helmet } from "react-helmet"
import MainLayout from "@/layouts/Main"
import TadaGovernance from "@/components/TadaGovernance"
import TadaGovernanceWallets from "@/components/TadaGovernanceWallets"
import TadaGovernanceEvents from "@/components/TadaGovernanceEvents"

const Page = () => {
  return (
    <MainLayout>
      <Helmet title="TADA Governance" />
      <TadaGovernance />
      <TadaGovernanceWallets />
      <TadaGovernanceEvents />
    </MainLayout>
  )
}

export default Page
